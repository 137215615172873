.quote-form {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    background-color: #002699;
    color: #fff;
    display: flex;
    max-width: 800px;
    width: 100%;
    height: 495px;

    &__image {
        img {
            height: 100%;
        }
    }

    &__content {
        padding: 32px;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 500;
        }

        .name {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    button.submit {
        background-color: #343a40;
        border-radius: 0;
        border: none;
    }
}

.quote-form-enter {
    opacity: 0;
}

.quote-form-enter-active {
    animation: fadeInDown 300ms forwards;
}

.quote-form-exit {
    opacity: 1;
}

.quote-form-exit-active {
    animation: fadeOutUp 300ms forwards;
}