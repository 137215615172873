.service-card {
    background-color: #002699;
    padding: 30px;
    color: #fff;
    text-align: center;
    -webkit-box-shadow: 0px 0px 36px -13px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 36px -13px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 36px -13px rgb(0 0 0 / 75%);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    font-size: 16px;

    h5 {
        font-size: large;
    }

    &__icon {
        width: 30px;
        object-fit: contain;
    }

    &__divider {
        background-color: rgba($color: #fff, $alpha: 0.5);
        width: 100%;
        height: 1px;
        margin: 8px 0;
    }
}