@import "../../../SharedStyles/variables.scss";

.admin-login-page {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: auto;
    padding: 16px;
    background-color: #F0F3F6;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__login-form {
        padding: 64px;
        box-sizing: border-box;
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: #fff;
        transition: all 0.3s ease;

        img {
            width: 80%;
            object-fit: contain;
        }

        input {
            background-color: transparent;
            border-radius: 0;
            border: 1px solid #f1f6f8;
            font-weight: 400;
            outline: 0;
            color: #495057;
            padding: 12px 8px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                border-color: #002699;
            }

            &::placeholder {
                color: #9b9ea1;
            }
        }

        button {
            border-radius: 0;

            &:hover {
                background-color: #002699;
                border-color: #002699;
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .admin-login-page {
            &__login-form {
                width: 100%;
            }
        }
    }
}