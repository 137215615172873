@import '../../SharedStyles/variables.scss';

.admin-layout {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: auto;
    background-color: #F0F4F6;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    .nav-item {
        position: relative;
        padding: 0 24px;
        padding-bottom: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: 0.1s linear;
        text-align: center;

        .icon {
            display: block;
            margin: 0 auto;
            margin-bottom: 8px;
        }

        &:hover {
            color: #002699;
        }
    }

    .nav-item-active {
        color: #002699;

        &::after {
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: red;
        }
    }

    &__header-top {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #f1f6f8;

        img.logo {
            width: 20%;
            object-fit: contain;
        }

        .profile {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0;
            font-weight: 600;
            cursor: pointer;

            span {
                display: block;
                margin-right: .5rem;
                font-size: 1rem;
            }

            img.avatar {
                height: 40px;
                width: 40px;
                border-radius: 4px;
                object-fit: cover;
                display: block;
            }

            .icon {
                display: inline-block;
                margin-left: 4px;
                font-size: .75rem;
            }

            .menu-list {
                position: absolute;
                top: 60px;
                right: 1rem;
                left: auto;
                font-size: 0.9rem;
                box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
                background-color: #fff;
                z-index: 2;

                .option {
                    padding: 16px 24px;
                    font-weight: normal;

                    &:hover {
                        background-color: #f3e1e1;
                    }

                    .icon {
                        color: #002699;
                        display: inline-block;
                        margin-right: 12px;
                    }

                    span {
                        display: inline;
                    }
                }
            }

            .mobile-menu {
                display: none;
            }
        }

        .mobile-menu {
            display: none;
        }
    }

    &__header-bottom {
        background-color: #fff;
        padding-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10%;
        align-items: center;
        position: sticky;
        top: 0;
        border-bottom: 1px solid #f1f6f8;
    }

    &__outlet {
        padding: 24px;
        flex: 1;
    }

    &__footer {
        background-color: #fff;
        padding: 24px 16px;
        margin: 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
    }

    @media (max-width: $breakpoint-phone) {
        &__header-top {
            position: relative;
            
            img.logo {
                width: unset;
                height: 50px;
                max-width: 50%;
            }

            .profile {
                span {
                    display: none;
                }
            }

            .mobile-menu {
                display: block;

                .icon {
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    z-index: 3;
                    position: absolute;
                    width: 100%;
                    padding: .5rem;
                    background-color: #fff;
                    left: 0;
                    right: 0;
                    margin-top: 24px;
                    top: auto;
                    box-shadow: 0 19px 34px -15px #f1f6f8;
                    padding: 0 12px;
                }
            }
        }

        &__header-bottom {
            display: none;
        }

        &__footer {
            text-align: center;
        }
    }
}