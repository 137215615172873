.custom-button {
    border-radius: 30px;
    background-color: #002699;
    border-color: #002699;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    font-size: 14px;
    line-height: 17px;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    padding: 12px 35px;
    outline: none;

    &:disabled {
        background-color: #ccc;
        border-color: #ccc;
        color: #000;
        pointer-events: none;
    }

    &:hover {
        background-color: transparent;
        border-color: #fff;
    }
}