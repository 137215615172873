@import "../../../SharedStyles/variables.scss";

.track-it-result {
    margin: 32px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 64px;
    width: 100%;

    .st {
        font-size: 16px;
        cursor: pointer;

        .icon {
            color: #002699;
        }

        transition: color 0.3s ease;

        &:hover {
            color: #002699;
        }
    }

    &__content {
        width: 100%;

        h3 {
            color: #747678;
            font-size: 25px;
            line-height: 26px;
            margin-bottom: 24px;
        }

        table,
        th,
        td {
            border: 1px solid #002699;
            border-collapse: collapse;
        }

        th,
        td {
            padding: 8px;
            text-align: start;
            color: #fff;
        }

        table {
            flex: 1;
            background-color: rgb(234, 156, 155);

            tr {
                &:first-child {
                    background-color: #002699;
                }
            }

            th {
                text-transform: uppercase;
            }

            &:last-child {
                width: 100%;
            }
        }

        &__up {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 24px;
            width: 100%;
        }

        &__down {
            width: 100%;
            overflow: hidden;
            overflow-x: auto;
            margin-top: 24px;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;

        table {
            width: 100%;
        }

        &__content {
            &__up {
                flex-direction: column;
            }
        }
    }
}