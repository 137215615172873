@import "../../../SharedStyles/variables.scss";

.home-page__freight-card {
    flex: 1;
    font-size: 14px;
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: #fff;

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        cursor: pointer;

        span {
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__content {
        text-align: start;
    }

    @media (max-width: $breakpoint-phone) {
        background-color: #002699;
    }
}