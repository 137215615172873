@import "../../SharedStyles/variables.scss";

.service {
    &__top {
        background-image: url(../../Assets/Images/about-us-bg.jpeg);
        padding: 0 10%;
        padding-top: 160px;
        padding-bottom: 64px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: grey;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: ease 1s;
        text-align: center;
    }

    &__content {
        line-height: 28px;

        .service-content {
            padding: 100px 10%;
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 32px;

            .main-content {
                flex: 0.75;

                &__title {
                    font-size: 23px;
                    line-height: 28px;
                }

                &__content {
                    font-size: 16px;
                    white-space: pre-line;
                    margin: 30px 0;
                }

                h4 {
                    font-size: 18px;
                }

                .accordion-container {
                    margin: 30px 0;
                }
            }

            .side-menu {
                flex: 0.25;

                .services-list {
                    margin-bottom: 40px;
                    background-color: #002699;

                    ul {
                        list-style: none;
                        display: block;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        margin-inline-start: 0px;
                        margin-inline-end: 0px;
                        padding-inline-start: 40px;
                        margin: 0px;
                        padding: 0px;

                        li {
                            display: list-item;
                            cursor: pointer;

                            div {
                                color: #fff;
                                padding: 10px 30px;
                                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                                transition: all 0.3s ease-in;

                                &:hover {
                                    background-color: #444;
                                }
                            }
                        }

                        li.active {
                            div {
                                background-color: #444;
                            }
                        }
                    }
                }

                .contact-widget {
                    border: 1px solid #777;
                    padding: 30px;
                    color: #4a4a4a;
                    margin-bottom: 40px;

                    .location, .call-center {
                        margin-bottom: 60px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &__content {
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            align-items: center;

                            .icon {
                                font-size: 36px;
                                line-height: 44px;
                            }

                            div {
                                span {
                                    display: block;

                                    &:nth-child(2) {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }
                }

                .contact-form {
                    color: #fff;
                    padding: 30px;
                    background-color: #474747;
                    form {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                    }

                    h3 {
                        font-size: 20px;
                        line-height: 27px;
                        margin-bottom: 24px;
                    }

                    button.submit {
                        background-color: #002699;
                        border-radius: 0;
                        border: none;
                        width: 100%;
                        padding: 15px;
                        
                    }
                }
            }

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
            }
        }
    }
}