@import "../../SharedStyles/variables.scss";

.our-service-section {
    padding: 100px 10%;
    background-image: url(../../Assets/Images/ba_1.jpg);
    background-size: cover;
    line-height: 28px;

    div.title {
        margin-bottom: 40px;
        text-align: center;

        .icon {
            font-size: 28px;
            color: #002699;
        }

        h2 {
            font-size: 28px;
            font-weight: 700;
            font-family: "Raleway", sans-serif;
            display: inline-block;
            position: relative;

            &::before {
                border-bottom: 2px solid #d1d1d1;
                content: "";
                width: 100px;
                position: absolute;
                left: -120px;
                right: 0;
                bottom: 12px;
            }

            &::after {
                border-bottom: 2px solid #d1d1d1;
                content: "";
                width: 100px;
                position: absolute;
                right: -120px;
                bottom: 12px;
            }
        }

        .des {
            opacity: .8;
        }
    }

    .lko {
        display: grid;
        grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
        gap: 32px;
        text-align: center;

        .lko-1,
        .lko-3 {
            div {
                margin-bottom: 48px;

                img {
                    height: 40px;
                    object-fit: contain;
                }

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                    line-height: 28px;
                    cursor: pointer;
                }

                span.des {
                    font-size: 16px;
                    opacity: .6;
                }
            }
        }

        .lko-2 {
            img {
                border-radius: 500px;
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .our-service-section {
        .lko {
            grid-template-columns: 100%;
        }
    }
}