@import "../../SharedStyles/variables.scss";

.home-page {

    &__top {
        padding: 0 10%;
        padding-top: 230px;
        padding-bottom: 64px;
        width: 100%;
        color: #fff;
        font-size: 16px;
        background-color: grey;
        background-image: url('../../Assets/Images/home-slide-1.jpeg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: ease 1s;

        &__freights {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 16px;
            align-items: stretch;
            margin-top: 128px;
        }
    }   

    &__content {
        line-height: 28px;

        section {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                padding: 100px 10%;
                background-image: url(../../Assets/Images/ba_1.jpg);
                background-size: cover;
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 24px;

                div.section1-col {
                    // flex: 1;
                    // overflow: hidden;

                    &:nth-child(1) {
                        p {
                            margin: 16px 0;
                            font-size: 16px;
                        }

                        .title-text {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                margin-right: 16px;
                                display: inline-block;
                            }

                            font-size: 26px;
                            line-height: 29px;
                            font-weight: 600;
                            color: #002699;
                        }

                        .read-more {
                            transition: background-color 300ms;
                            border: none;
                            margin-top: 16px;
                        
                            &:hover {
                                background-color: #868e96;
                            }
                        }

                        .accordion-container {
                            margin-top: 80px;
                        }
                    }

                    &:nth-child(2) {
                        display: grid;
                        grid-template-columns: calc(50% - (32px / 2)) calc(50% - (32px / 2));
                        gap: 32px;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        &__content {
            section {
                &:nth-child(2) {
                    grid-template-columns: 100%;
                }
            }
        }
    }

    @media (max-width: $breakpoint-phone) {
        &__top {
            .home-page__info-card {
                margin: auto;
                text-align: center;
            }

            &__freights {
                display: none;
            }
        }

        &__content {
            section {
                &:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    margin: 30px 10%;

                    .content-freight {
                        padding: 30px;
                        background-color: #002699;
                    }
                }

                &:nth-child(2) {
                    div.section1-col {
                        &:nth-child(2) {
                            grid-template-columns: 100%;
                        }
                    }
                }
            }
        }
    }
}