.service-card-v2 {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 40px 30px;
    text-align: center;
    color: #fff;
    transition: all 0.25s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;

    img {
        display: block;
    }

    span {
        display: block;
        color: #fff;
        font-weight: 600;
    }

    &:hover {
        background-color: #002699;
        box-shadow: 0px 0px 36px -13px rgb(0 0 0 / 75%);
    }
}