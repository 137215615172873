@import "../../../SharedStyles/variables.scss";

div.tracker {
    margin: 32px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 64px;
    max-width: 600px;

    .st {
        font-size: 16px;
        cursor: pointer;

        .icon {
            color: #002699;
        }

        transition: color 0.3s ease;

        &:hover {
            color: #002699;
        }
    }

    .form {
        flex: 1;

        h3 {
            color: #747678;
            font-size: 25px;
            line-height: 26px;
        }

        .airwaybill-input {
            padding: 10px;
            margin: 24px 0;
            width: 100%;
            outline: none;
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            transition: box-shadow 0.3s, border 0.3s;

            &:focus {
                border: solid 1px #002699;
                box-shadow: 0 0 5px 1px #002699;
            }
        }

        button.track-it {
            border-radius: 0;
            border-color: #000;

            &:hover {
                background-color: #002699;
                border-color: #002699;
            }
        }
    }

    @media (max-width: $breakpoint-phone) {
        flex-direction: column;
    }
}